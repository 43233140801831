import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "./components/Layout";
import MarketingAgencyPage from "./components/Pages/MarketingAgencyPage";
import ContactPage from "./components/Pages/ContactPage";
import TechStartupPage from "./components/Pages/TechStartupPage";
import ServiceDetailsPage from "./components/Pages/ServiceDetailsPage";
import ErrorPage from "./components/Pages/ErrorPage";
import DataProduct from "./components/Pages/DataProduct";
import PaypalPage from "./components/Pages/PaypalPage";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
    <Routes>
      {/* Start Light Mode */}
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<MarketingAgencyPage />} />
        <Route path="vin-check" element={<ServiceDetailsPage />} />
        <Route path="dealer-program" element={<TechStartupPage />} />
        <Route path="data-product" element={<DataProduct />} />
        <Route path="packages" element={<PaypalPage />} />
        {/* <Route path="service" element={<ServicePage />} />
        <Route
          path="service/:serviceDetailsId"
          element={<ServiceDetailsPage />}
        />
        <Route path="blog" element={<BlogPage />} />
        <Route path="blog-list" element={<BlogListPage />} />
        <Route path="blog/:blogDetailsId" element={<BlogDetailsPage />} />
        <Route path="portfolio" element={<PortfolioPage />} />
        <Route
          path="portfolio/:portfolioDetailsId"
          element={<PortfolioDetailsPage />}
        />
        <Route path="case-study-details" element={<CaseStudyDetailsPage />} />
        <Route path="team" element={<TeamPage />} />
        <Route path="team/:teamDetailsId" element={<TeamDetailsPage />} /> */}
        <Route path="contact" element={<ContactPage />} />
      </Route>
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
